<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Başlık"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="itemData.title"
              placeholder="Başlık"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Video Bağlantısı"
            label-for="video_url"
          >
            <b-form-input
              id="video_url"
              v-model="videoUrl"
              placeholder="Video Bağlantısı"
              @input="getEmbedCode"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="text-center"
        >
          <b-embed
            v-if="itemData.video_embed"
            type="iframe"
            aspect="16by9"
            :src="youtubeUrl + itemData.video_embed"
          />
          <div
            v-else
            class="bg-light py-5 rounded text-muted"
          >
            <div class="my-2">
              <div>
                <FeatherIcon icon="VideoIcon" />
              </div>
              <div>
                Video Önizleme
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col
        cols="12"
      >
        <b-card title="Kapak Görseli">
          <b-img
            v-if="itemData.video_cover"
            :src="imageUrl + '/media/video_gallery/' + itemData.video_cover"
            thumbnail
            center
          />
          <div class="text-center mt-1">
            <b-button
              variant="primary"
              @click="setModal('video_gallery')"
            >
              <FeatherIcon icon="UploadIcon" />
              Görsel Yükle
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <cropper-image
      v-if="cropperSettings.type"
      :modal-status="cropperSettings.modalStatus"
      :close-modal="closeModal"
      :file-name="cropperSettings.fileName"
      :folder="cropperSettings.folder"
      :ratio="cropperSettings.ratio"
      @uploadImage="setUploadImage"
    />
    <b-button
      variant="primary"
      :disabled="!itemData.video_embed || itemData.submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BCol, BFormGroup, BFormInput, BImg, BRow, BButton, BEmbed,
} from 'bootstrap-vue'
import CropperImage from '@/layouts/components/common/CropperImage.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BButton,
    BEmbed,
    CropperImage,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      imageUrl: this.$store.state.app.baseURL,
      videoUrl: null,
      youtubeUrl: 'https://www.youtube.com/embed/',
      cropperSettings: {
        modalStatus: false,
        fileName: null,
        folder: null,
        ratio: null,
        type: null,
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['videoGallery/dataItem']
    },
  },
  methods: {
    setModal(type) {
      if (type === 'gallery') {
        this.cropperSettings = {
          modalStatus: true,
          fileName: 'gallery-image.jpg',
          folder: 'gallery',
          ratio: 1280 / 800,
          type: 'gallery',
        }
      }
      if (type === 'video_gallery') {
        this.cropperSettings = {
          modalStatus: true,
          fileName: 'gallery-image.jpg',
          folder: 'video_gallery',
          ratio: 1280 / 800,
          type: 'gallery',
        }
      }
    },
    closeModal() {
      this.cropperSettings = {
        modalStatus: false,
        fileName: null,
        folder: null,
        ratio: null,
        type: null,
      }
    },
    setUploadImage(item) {
      if (this.cropperSettings.type === 'gallery') {
        this.itemData.video_cover = item.image
      }
      this.cropperSettings.modalStatus = false
    },
    getEmbedCode() {
      this.$store.dispatch('videoGallery/getEmbedCode', this.videoUrl)
        .then(res => {
          this.itemData.video_embed = res.id
        })
    },
  },
}
</script>
